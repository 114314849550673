<template>
  <v-card-text eagle-list="main">
    <v-checkbox
      v-if="hasSelectFeature"
      @change="toggleSelected"
      v-model="selected"
      class="list-item-checkbox"
      :disabled="disabledSelect"
      :style="{ zIndex: $zIndexConfig.listCardMode }"
    ></v-checkbox>

    <v-list>
      <v-list-item class="grow">
        <!-- <v-list-item-avatar
          v-if="hasAvatarConfig"
          color="grey darken-1"
          size="50"
        >
          <img v-if="photoUrl" :src="photoUrl" />

          <v-icon v-if="!photoUrl" size="24" dark>
            {{ avatarIcon }}
          </v-icon>
        </v-list-item-avatar> -->

        <v-list-item-content>
          <v-list-item-title v-if="title" class="mb-2">
            {{ title }}
          </v-list-item-title>

          <div class="d-flex">
            <v-list-item-subtitle v-if="subtitle" class="mr-2">
              {{ subtitle }}
            </v-list-item-subtitle>

            <template v-if="otherValues">
              <v-list-item-subtitle
                v-for="value in otherValues"
                :key="value"
                class="primary--text"
              >
                {{ value }}
              </v-list-item-subtitle>
            </template>
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card-text>
</template>

<script lang="babel" type="text/babel">
import listRow from '@/components/list/mixins/listRow'
export default {
  mixins: [listRow],
  props: {
    listKey: String,
    row: Object,
  },
  computed: {
    hasSelectFeature() {
      return this.$store.getters[`list/${this.listKey}/hasSelectFeature`]
    },
    cardConfig() {
      return this.$store.getters[`list/${this.listKey}/cardConfig`]
    },
    title() {
      if(!this.cardConfig) return false
      if(typeof this.cardConfig.title != 'function') return null
      return this.cardConfig.title(this.row)
    },
    subtitle() {
      if(!this.cardConfig) return false
      if(typeof this.cardConfig.subtitle != 'function') return null
      return this.cardConfig.subtitle(this.row)
    },
    otherValues() {
      if(!this.cardConfig) return false
      if(typeof this.cardConfig.otherValues != 'function') return null
      return this.cardConfig.otherValues(this.row)
    },
    hasAvatarConfig() {
      if(!this.cardConfig) return false
      return typeof this.cardConfig.avatarPhoto === 'function'
    },
    photoUrl() {
      const photo = this.cardConfig.avatarPhoto(this.row)
      return this.$helper.getPhotoUrl(photo, ['small', 'middle']) || null
    },
    avatarIcon() {
      if(this.cardConfig.avatarIcon) return this.cardConfig.avatarIcon
      return 'fa fa-image'
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped>
*[eagle-list="main"]
  .list-item-checkbox
    position: absolute
    right: 0
    top: 0
</style>
